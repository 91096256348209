import { ApiService as api } from "../Services/APIService";
import { TemplateService } from "../Services/TemplateService";

const seriesScrollerTemplate = "SeriesScroller";
const seriesDetailTemplate = "SeriesDetail";
const sermonDetailTemplate = "SermonDetail";
const sermonNotesTemplate = "SermonDetailNotes";

const sermonLatestTemplate = "SermonLatest";
const sermonLatestCardTemplate = "SermonLatestCard";
const sermonLatestCardWideTemplate = "SermonLatestCardWide";

const recentSermonScrollerTemplate = "RecentSermonScroller";
const recentSermonsToLoad = 15;

export class SermonModule {


    static SetupSermonInit() {

        DEBUG && console.info('Sermon Detail *--> Init');

        var elements = document.querySelectorAll('[data-component="sermonDetail"]');

        elements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var sermonId = element.getAttribute("data-id");
            var sermonDetailsUrl = element.getAttribute('data-sermonUrl');
            var seriesDetailUrl = element.getAttribute('data-seriesUrl');
            var loadNotes = element.getAttribute('data-loadNotes');

            var openNotes = false;

            if (loadNotes == 'True') {
                openNotes = true;
            }

            DEBUG && console.info('**************************************************');
            DEBUG && console.info('Init Sermon Detail');
            DEBUG && console.info(`Element:                 ${element.id}`);
            DEBUG && console.info(`Sermon Details:          ${sermonDetailsUrl}`);
            DEBUG && console.info(`Series Details:          ${seriesDetailUrl}`);
            DEBUG && console.info(`Open Notes:              ${openNotes}`);
            DEBUG && console.info('**************************************************');

            this.InitSermonDetail(element.id, sermonId, sermonDetailsUrl, seriesDetailUrl, openNotes);
        });


        var latestElements = document.querySelectorAll('[data-component="sermonLatest"]');

        latestElements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var sermonDetailsUrl = element.getAttribute('data-sermonUrl');
            var seriesType = element.getAttribute('data-seriesType');
            var sermonDetailsUrl = element.getAttribute('data-sermonUrl');
            var seriesDetailUrl = element.getAttribute('data-seriesUrl');
            var requireMedia = element.getAttribute('data-requireMedia');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info('Init Sermon Latest');
            DEBUG && console.info(`Element:${element.id}`);
            DEBUG && console.info(`Congregation:${congregationId}`);
            DEBUG && console.info(`Series Type:${seriesType}`);
            DEBUG && console.info(`Sermon Details:${sermonDetailsUrl}`);
            DEBUG && console.info(`Series Details:${seriesDetailUrl}`);
            DEBUG && console.info(`Require Media:${requireMedia}`);
            var title = element.getAttribute('data-title');
            DEBUG && console.info('**************************************************');

            this.InitSermonLatestDetail(element.id, congregationId, seriesType, sermonDetailsUrl, seriesDetailUrl, requireMedia, sermonLatestTemplate, title);
        });

        var latestCardElements = document.querySelectorAll('[data-component="sermonLatestCard"]');

        latestCardElements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var sermonDetailsUrl = element.getAttribute('data-sermonUrl');
            var seriesType = element.getAttribute('data-seriesType');
            var sermonDetailsUrl = element.getAttribute('data-sermonUrl');
            var seriesDetailUrl = element.getAttribute('data-seriesUrl');
            var requireMedia = element.getAttribute('data-requireMedia');
            var title = element.getAttribute('data-title');

            var editMode = element.getAttribute('data-isEdit');
            var isEdit = false;
            if (editMode == 'True') {
                isEdit = true;
            }

            DEBUG && console.info('**************************************************');
            DEBUG && console.info('Init Sermon Latest Card');
            DEBUG && console.info(`Element:${element.id}`);
            DEBUG && console.info(`Congregation:${congregationId}`);
            DEBUG && console.info(`Series Type:${seriesType}`);
            DEBUG && console.info(`Sermon Details:${sermonDetailsUrl}`);
            DEBUG && console.info(`Series Details:${seriesDetailUrl}`);
            DEBUG && console.info(`Require Media:${requireMedia}`);
            DEBUG && console.info('**************************************************');

            this.InitSermonLatestDetail(element.id, congregationId, seriesType, sermonDetailsUrl, seriesDetailUrl, requireMedia, sermonLatestCardTemplate, title, isEdit);
        });

        var latestCardWideElements = document.querySelectorAll('[data-component="sermonLatestCardWide"]');

        latestCardWideElements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var sermonDetailsUrl = element.getAttribute('data-sermonUrl');
            var seriesType = element.getAttribute('data-seriesType');
            var sermonDetailsUrl = element.getAttribute('data-sermonUrl');
            var seriesDetailUrl = element.getAttribute('data-seriesUrl');
            var requireMedia = element.getAttribute('data-requireMedia');
            var title = element.getAttribute('data-title');

            var editMode = element.getAttribute('data-isEdit');
            var isEdit = false;
            if (editMode == 'True') {
                isEdit = true;
            }

            DEBUG && console.info('**************************************************');
            DEBUG && console.info('Init Sermon Latest Card');
            DEBUG && console.info(`Element:${element.id}`);
            DEBUG && console.info(`Congregation:${congregationId}`);
            DEBUG && console.info(`Series Type:${seriesType}`);
            DEBUG && console.info(`Sermon Details:${sermonDetailsUrl}`);
            DEBUG && console.info(`Series Details:${seriesDetailUrl}`);
            DEBUG && console.info(`Require Media:${requireMedia}`);
            DEBUG && console.info('**************************************************');

            this.InitSermonLatestDetail(element.id, congregationId, seriesType, sermonDetailsUrl, seriesDetailUrl, requireMedia, sermonLatestCardWideTemplate, title, isEdit);
        });
    }



    static SetupSeriesScroller() {
        DEBUG && console.info('Media Scroller *--> Init');
        var elements = document.querySelectorAll('[data-component="mediaScroller"]');

        elements.forEach(element => {
            var congregationId = element.getAttribute('data-congregationId');
            var seriesTypeId = element.getAttribute('data-seriesTypeId');
            var seriesDetailPageUrl = element.getAttribute('data-seriesDetailUrl');
            var sermonDetailPageUrl = element.getAttribute('data-sermonDetailUrl');
            var title = element.getAttribute('data-title');
            var mode = element.getAttribute('data-mode');

            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Congregation:            ${congregationId}`);
            DEBUG && console.info(`Series Type:             ${seriesTypeId}`);
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info(`Series Detail Url:       ${seriesDetailPageUrl}`);
            DEBUG && console.info(`Sermon Detail Url:       ${sermonDetailPageUrl}`);
            DEBUG && console.info(`Mode:                    ${mode}`);
            DEBUG && console.info('**************************************************');

            this.InitSeriesScroller(element.id, congregationId, seriesTypeId, seriesDetailPageUrl, sermonDetailPageUrl, mode, title);
        });
    }

    static async InitSeriesScroller(elementId, congregationId, seriesTypeId, seriesDetailPageUrl, sermonDetailPageUrl, mode, title) {       
        const container = document.getElementById(elementId);

        if (mode === 'RecentSermons') {
            var data = await this.GetRecentSermons(congregationId, sermonDetailPageUrl, seriesDetailPageUrl, true, recentSermonsToLoad);
            var modelData = { "models": data };

            var content = await TemplateService.GetRenderedTemplate(recentSermonScrollerTemplate, modelData);

            container.innerHTML = content;
        }
        else {
            var data = await this.GetSeries(congregationId, seriesTypeId);
            var modelData = { "models": data, "detailPageUrl": seriesDetailPageUrl, "title": title };

            var content = await TemplateService.GetRenderedTemplate(seriesScrollerTemplate, modelData);

            container.innerHTML = content;
        }

    }

    static async GetSeries(congregationId, seriesTypeId) {
        var data = await api.getData(`Sermon/GetCampusSeries?congregationId=${congregationId}&seriesTypeId=${seriesTypeId}`);
        DEBUG && console.info(data);
        return data;
    }

    static async GetRecentSermons(congregationId, sermonDetailsUrl, seriesDetailUrl, requireMedia, count) {
        var data = await api.getData(`Sermon/GetRecentSermons/?congregationId=${congregationId}&sermonDetailsUrl=${sermonDetailsUrl}&seriesDetailUrl=${seriesDetailUrl}&requireMedia=${requireMedia}&Count=${count}`);

        DEBUG && console.info(data);
        return data;
    }


    static async InitSeriesDetail(elementId, seriesId, sermonDetailsUrl) {
        const container = document.getElementById(elementId);
        var data = await this.GetSeriesDetails(seriesId, sermonDetailsUrl);

        var content = await TemplateService.GetRenderedTemplate(seriesDetailTemplate, data);

        container.innerHTML = content;
    }

    static async GetSeriesDetails(seriesId, sermonDetailsUrl) {
        var data = await api.getData(`Sermon/GetSeries/${seriesId}?sermonDetailsUrl=${sermonDetailsUrl}`);
        DEBUG && console.info(data);
        return data;
    }



    static async InitSermonDetail(elementId, sermonId, sermonDetailsUrl, seriesDetailUrl, openNotes) {
        let self = this;

        var localTemplate = sermonDetailTemplate;

        if (openNotes) {
            localTemplate = sermonNotesTemplate;
        }

        const container = document.getElementById(elementId);
        var data = await this.GetSermonDetails(sermonId, sermonDetailsUrl, seriesDetailUrl);

        // Add API Root to Data
        data.apiRoot = apiRoot;

        var content = await TemplateService.GetRenderedTemplate(localTemplate, data);

        container.innerHTML = content;

        // Initialize the Player
        // Get Player Element
        const currentSermonPlayerEl = container.querySelectorAll(`div[data-component="sermonPlayer"]`)[0];


        if (data.Video && data.Video.Link_URL && !openNotes) {

            self.playVideo(data, currentSermonPlayerEl);

            const btnVideo = document.getElementById('btnVideo');
            btnVideo.style.display = "inline";

            btnVideo.addEventListener('click', function () {
                self.playVideo(data, currentSermonPlayerEl, true);
            });
        }

        if (data.Audio && data.Audio.Link_URL && !openNotes) {

            if (!data.Video || !data.Video.Link_URL) {
                self.playAudio(data, currentSermonPlayerEl);
            }
            
            const btnAudio = document.getElementById('btnAudio');
            btnAudio.style.display = "inline";

            btnAudio.addEventListener('click', function () {
                self.playAudio(data, currentSermonPlayerEl, true);
            });
        }

        if (!openNotes) {
            // Get current Sermon li element
            const currentSermonEl = document.querySelectorAll(`li[data-id="${sermonId}"]`)[0];
            DEBUG && console.info(currentSermonEl);

            // Highlight Current Sermon
            const currentSermonImageEl = document.querySelectorAll(`li[data-id="${sermonId}"] > a > figure > picture > img`)[0];
            DEBUG && console.info(currentSermonImageEl);
            currentSermonImageEl.classList.add('highlighted');

            // Hide if only 1 Sermon
            if (data.Series.Sermons.count < 2) {
                const seriesScroller = document.getElementById("SermonSeriesScroller");
                seriesScroller.style.display = "none";
            }

            // Create small Timeout before Scrolling to Current Sermon
            setTimeout(() => {
                // Scroll to Center the current Sermon
                this.CenterSermon(currentSermonEl);
            }, 350);
        }
        await this.SetupSermonNotes(sermonId, 'sermonNotes', 'containerSermonNotes', 'btnSermonNotes');
    }

    static CenterSermon(currentSermonEl) {
        DEBUG && console.info('Centering Current Sermon');
        currentSermonEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }

    static async GetSermonDetails(sermonId, sermonDetailsUrl, seriesDetailUrl) {
        var data = await api.getData(`Sermon/GetSermon/${sermonId}?sermonDetailsUrl=${sermonDetailsUrl}&seriesDetailUrl=${seriesDetailUrl}`);
        DEBUG && console.info(data);
        return data;
    }



    static async InitSermonLatestDetail(elementId, congregationId, seriesType, sermonDetailsUrl, seriesDetailUrl, requireMedia, template, title, isEdit) {
        const container = document.getElementById(elementId);
        var data = await this.GetLastestSermon(congregationId, seriesType, sermonDetailsUrl, seriesDetailUrl, requireMedia);

        var template = template;

        // Add Title to the Data Object
        var sermonObj = {};
        sermonObj.title = title;
        sermonObj.sermon = data;
        sermonObj.isEdit = isEdit;

        var content = await TemplateService.GetRenderedTemplate(template, sermonObj);

        container.innerHTML = content;

        if (!isEdit) {
            container.onclick = () => {
                window.location.href = data.SermonUrl;
            };
        }
    }

    static async GetLastestSermon(congregationId, seriesType, sermonDetailsUrl, seriesDetailUrl, requireMedia) {
        var data = await api.getData(`Sermon/GetLatestSermon/?congregationId=${congregationId}&serviceTypeId=${seriesType}&sermonDetailsUrl=${sermonDetailsUrl}&seriesDetailUrl=${seriesDetailUrl}&requireMedia=${requireMedia}`);

        DEBUG && console.info(data);
        return data;
    }

    static async SetupSermonNotes(sermonId, sermonNotesElement, sermonNotesContainerElement, sermonNotesButtonElement) {
        const elementButton = document.getElementById(sermonNotesButtonElement);
        const elementNotesContainer = document.getElementById(sermonNotesContainerElement);
        const elementNotes = document.getElementById(sermonNotesElement);
        const elementPrintNotes = document.getElementById('sermonPrintNotes');

        var data = await api.getData(`Sermon/GetSermonNotes/${sermonId}`);

        if (data) {
            elementNotes.innerHTML = data.Sermon_Notes;
            elementPrintNotes.innerHTML = data.Sermon_Notes;
            elementButton.addEventListener('click', (evt) => {
                if (elementNotesContainer.style.display == 'none') {
                    elementNotesContainer.style.display = 'block';
                }
                else {
                    elementNotesContainer.style.display = 'none';
                }
            });

            elementButton.style.display = 'block';
        }
    }

    static playVideo(data, currentSermonPlayerEl, switchToVideo) {
        var videoplaylist = [
            {
                'title': `${data.Title}`,
                'image': `${data.Series.Series.Image_URL}`,
                'file': 'https://ep-default-calvaryccm.eastus.streaming.mediakind.com/fff1426c-b562-4a72-a6b8-6e12c33c0cd6/IntroVideo.ism/manifest(format=m3u8-cmaf)'
            },
            {
                'title': `${data.Title}`,
                'image': `${data.Series.Series.Image_URL}`,
                'file': `${data.Video.Link_URL}`
            },
            {
                'title': `${data.Title}`,
                'image': `${data.Series.Series.Image_URL}`,
                'file': 'https://ep-default-calvaryccm.eastus.streaming.mediakind.com/712c98c5-2506-477e-beea-c59bb39c3d58/Outro.ism/manifest(format=m3u8-cmaf)'
            }];

        if (switchToVideo) {
            jwplayer(currentSermonPlayerEl.id).remove();
        }

        jwplayer(currentSermonPlayerEl).setup({
            height: 'auto',
            width: '100%',
            nextUpDisplay: false,
            playlist: videoplaylist
        });
    }

    static playAudio(data, currentSermonPlayerEl, switchToAudio) {
        var audioplaylist = [{
            'title': `${data.Title}`,
            'image': `${data.Series.Series.Image_URL}`,
            'file': `${data.Audio.Link_URL}`
        }];

        if (switchToAudio) {
            jwplayer(currentSermonPlayerEl.id).remove();
        }

        jwplayer(currentSermonPlayerEl).setup({
            height: 'auto',
            width: '100%',
            nextUpDisplay: false,
            playlist: audioplaylist
        });
    }
}


